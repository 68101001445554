import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiResponse } from '../shared/_models/api-response.model';
import { BaseHttpService } from './_base/base-http.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class JsonHttpService extends BaseHttpService {
    constructor(
        private http: HttpClient,
        router: Router
    ) {
        super(router);
    }

    getMany<TReturn>(functieUrl: string, apiVersion = "v1/") {
        return this.http.get(this.getFullUrl(functieUrl, apiVersion), this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
        );
    }

    get<TReturn>(functieUrl: string, apiVersion = "v1/") {
        return this.http.get(this.getFullUrl(functieUrl, apiVersion), this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
        );
    };

    post<TPost, TReturn>(functieUrl: string, body: TPost, apiVersion = "v1/") {
        return this.http.post(this.getFullUrl(functieUrl, apiVersion), body, this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
        );
    }

    put<TPut, TReturn>(functieUrl: string, body: TPut, apiVersion = "v1/") {
        return this.http.put(this.getFullUrl(functieUrl, apiVersion), body, this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
        );
    }

    patch<TPatch, TReturn>(functieUrl: string, body: TPatch, apiVersion = "v1/") {
        return this.http.patch(this.getFullUrl(functieUrl, apiVersion), body, this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
        );
    }

  deleteWithBody<TDelete, TReturn>(functieUrl: string, body: TDelete, apiVersion = "v1/") {
    return this.http.delete(this.getFullUrl(functieUrl, apiVersion), this.jwtWithBody(body))
      .pipe(
        map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
        catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
      );
  }

  delete<TReturn>(functieUrl: string, apiVersion = "v1/") {
      return this.http.delete(this.getFullUrl(functieUrl, apiVersion), this.jwt())
      .pipe(
          map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
          catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response)))
      );
  }

  private jwtWithBody<TBody>(body: TBody) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = this.addTokenToHeader(headers);
    return { headers: headers, body: body };
  }

    private jwt() {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = this.addTokenToHeader(headers);
        return { headers: headers };
    }
}
