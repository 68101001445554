import { ApiError } from '../_models/api-error.model';
import { ErrorsConvertService } from '../_services/errors-convert.service';
import { NoResultDto } from '../_models/no-result.model';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { Alert } from './alert.model';
import { AlertType } from './alert-type.enum';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private messageSubject = new Subject<Alert>();
    private clearSubject = new Subject<NoResultDto>();

    constructor(
        private readonly router: Router,
        private errorsConvertService: ErrorsConvertService
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.clearSubject.next();
            }
        });
    }

    public success(message: string) {
        this.messageSubject.next(new Alert(AlertType.success, message));
    }

    public errors(errors: ApiError[]) {
        for (let i = 0; i < errors.length; i++) {
            let error = errors[i];

            if (error.code !== 'BRSS_4011') {
                let message = this.errorsConvertService.toMessage(error, error.name);
                this.messageSubject.next(new Alert(AlertType.error, message));
            }
        }
    }

    public manualErrorMessage(message: string) {
        this.messageSubject.next(new Alert(AlertType.error, message));
    }

    public getMessage(): Observable<Alert> {
        return this.messageSubject.asObservable();
    }

    public clear(): Observable<NoResultDto> {
        return this.clearSubject.asObservable();
    }
}