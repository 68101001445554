import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-basemodal',
  templateUrl: './basemodal.component.html',
  styleUrls: ['./basemodal.component.css']
})
export class BasemodalComponent implements OnInit {

  public result: boolean;
  public onHide: EventEmitter<boolean> = new EventEmitter();
  public modalRef: BsModalRef

  constructor(modalRef: BsModalRef) {
    this.modalRef = modalRef;
    this.result = false;
   }

  ngOnInit(): void {
    
  }

  public close() {
    this.onHide.emit(this.result);
    this.modalRef.hide();
  }

}
