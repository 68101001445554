interface PossibleError {
    errorCode: string;
    message: string;
}

export class ErrorContainer {
    public errors: PossibleError[] = [
        { 'errorCode': 'BRSS_4001', 'message': '{name} is verplicht' },
        { 'errorCode': 'BRSS_4002', 'message': '{name} is geen geldig JSON object' },
        { 'errorCode': 'BRSS_4011', 'message': 'Geen geldige token meegegeven' },
        { 'errorCode': 'BRSS_4012', 'message': 'Inlog gegevens zijn ongeldig' },
        { 'errorCode': 'BRSS_4013', 'message': 'Account is nog niet geactiveerd, controleer je e-mail voor een code' },
        { 'errorCode': 'BRSS_4031', 'message': 'Geen rechten om content in te zien' },
        { 'errorCode': 'BRSS_4041', 'message': 'De opgevraagde pagina kon niet gevonden worden' },
        { 'errorCode': 'BRSS_4042', 'message': 'De opgevraagde pagina kon niet gevonden worden' },
        { 'errorCode': 'BRSS_4091', 'message': '{name} \'{value}\' bestaat al' },
        { 'errorCode': 'BRSS_4092', 'message': '{name} wordt nog gebruikt, en kan daarom niet verwijderd worden' },
        { 'errorCode': 'BRSS_5001', 'message': 'Er is iets fout gegaan' },
        { 'errorCode': 'BRSS_5002', 'message': 'Er is iets fout gegaan' },
        { 'errorCode': 'BRSS_5003', 'message': 'Er is iets fout gegaan' },
        { 'errorCode': 'BSRC_4001', 'message': '{name} is niet geldig' },
        { 'errorCode': 'BSRC_4002', 'message': '{name} is niet geldig' },
        { 'errorCode': 'BSRC_4003', 'message': '{name} is niet geldig' },
        { 'errorCode': 'BSRC_4004', 'message': '{name} voldoet niet aan alle eisen, de minimale lengte is 8 en er moet minimaal een cijfer, hoofdletter en kleine letter in zitten' },
        { 'errorCode': 'BSRC_4005', 'message': '{name} is langer dan toegestaan, het maximaal aantal characters is {extra}' },
        { 'errorCode': 'BSRC_4006', 'message': '{name} is een te groot getal, het maximale aantal cijfers is {extra}' },
        { 'errorCode': 'BSRC_4007', 'message': '{name} is korter dan toegestaan, het minimaal aantal characters is {extra}' },
        { 'errorCode': 'BSRC_4008', 'message': '{name} is niet geldig' },
        { 'errorCode': 'BSRCC_4031', 'message': 'Geen rechten om content aan te passen' },
        { 'errorCode': 'BSRCC_4041', 'message': 'Bestand \'{extra}\' bestaat niet' },
        { 'errorCode': 'BSRCC_4091', 'message': 'Er bestaat al een bestand met de naam \'{value}\', verander de naam en probeer het opnieuw' },
        { 'errorCode': 'BSRC_4009', 'message': 'De opgegeven waarde valt buiten de opgegeven grenzen' }
    ]
}