import { Injectable } from '@angular/core';
import { ApiError } from '../shared/_models/api-error.model';
import { ErrorContainer } from '../_models/error-container.model';

@Injectable({
    providedIn: 'root'
})
export class ErrorsConvertService {

    private errorContainer = new ErrorContainer();

    public toMessage(error: ApiError, naam: string = null): string {
        let found = this.errorContainer.errors.find((err) => err.errorCode === error.code);
        if (found) {
            return found.message
                .replace('{name}', naam === null ? error.name : naam)
                .replace('{extra}', error.extra)
                .replace('{value}', error.value);
        }

        return error.code;
    }
}