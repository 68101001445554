import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './configuration/guards/auth.guard';

const routes: Routes = [
  { 
      path: 'login', 
      loadChildren: () => import('./guest/guest.module').then(module => module.GuestModule)
  },
  {
      path: 'dashboard',
      loadChildren: () => import('./dashboard/dashboard.module').then(module => module.DashboardModule),
      canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy'} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
