import { Injectable } from '@angular/core';
import { JsonHttpService } from '../../shared/_services/json-http.service';
import { TryLogin } from './try-login.model';
import { NoResultDto } from '../../shared/_models/no-result.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    constructor(
        private httpCallService: JsonHttpService
    ) { }

    login<TReturn>(functieUrl: string, dto: TryLogin) {
        return this.httpCallService.post<TryLogin, TReturn>(functieUrl, dto);
    }

    logout() {
        this.httpCallService.delete('administrators/' + JSON.parse(localStorage.getItem('currentUser')).id + '/logout').subscribe(
            (resp) => {
                if (!resp.hasErrors()) {
                    // remove user from local storage to log user out
                    localStorage.removeItem('currentUser');
                }
            });
    }

    validateToken() {
        return this.httpCallService.get<NoResultDto>('administrators/validatetoken');
    }
}