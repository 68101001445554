import { Component, OnInit } from '@angular/core';

import { Alert } from './alert.model';
import { AlertType } from './alert-type.enum';
import { AlertService } from './alert.service';
import { timer } from 'rxjs';

import './alert.component.scss';

@Component({
    selector: 'alert',
    templateUrl: './alert.component.html'
})

export class AlertComponent implements OnInit  {
    public readonly AlertType = AlertType;

    private messageHeight = 67;
    private closeTimerMS = 8000;

    public messages: Alert[] = [];

    constructor(private readonly alertService: AlertService) { }

    public ngOnInit() {
        this.alertService.getMessage().subscribe((message) => this.add(message));
        this.alertService.clear().subscribe(() => this.clear());
    }

    public top(i: number): string {
        return i * this.messageHeight + 'px';
    }

    public close(message: Alert) {
        let index = this.messages.indexOf(message);
        this.messages.splice(index, 1);
    }

    private add(message: Alert) {
        if (this.messages.length == 5) {
            this.messages.splice(0, 1);
        }

        this.messages.push(message);
        this.setTimer(message);
    }

    private setTimer(message: Alert) {
        timer(this.closeTimerMS).subscribe(() => {
            message.isRemoved = true;
            timer(1000).subscribe(() => this.close(message))
        });
    }

    private clear() {
        this.messages.length = 0;
    }
}