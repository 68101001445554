import {AlertType} from './alert-type.enum';

export class Alert {
    public type: AlertType;
    public text: string;
    public header: string;
    public isRemoved: boolean;

    constructor(type: AlertType, text: string, header: string = null) {
        this.type = type;
        this.text = text;
        this.header = header;
    }
}