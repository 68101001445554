import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { DataService } from './shared/_services/data.service';

import './_styles/app.scss';
import { AuthenticationService } from './guest/login/authentication.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private router: Router,
        private dataService: DataService,
        private location: Location,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit(): void {

        let relativeUrl = window.location.href.replace(location.origin, '');
        if (this.dataService.hasCurrentUser()) {
            this.authenticationService.validateToken().subscribe((response) => {
                if (response.hasErrors()) {
                    this.router.navigate(['/login'], { queryParams: { returnUrl: relativeUrl } });
                } else {
                    if (!relativeUrl.startsWith('/dashboard')) {
                        this.router.navigate(['/dashboard/home']);
                    } else {
                        this.router.navigate([relativeUrl]);
                    }
                }
            });
        } else {
            this.router.navigate(['/login']);
        }
    }
 }